import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import ArrowLeft from "../icons/arrow-left"
// import { Link } from "gatsby"
import { Heading, Styled } from "theme-ui"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Nicht gefunden" meta={[{
        name: `robots`,
        content: `noindex`,
      }]} />
      <Heading as="h1">404: Seite nicht gefunden</Heading>
      <Styled.p>Wir können die von dir gesuchte Seite nicht finden. Falls du über einen Link hier gelandet bist, zeigte der vermutlich noch auf eine alte Seite, die mittlerweile nicht mehr verfügbar ist.</Styled.p>

      <Styled.p>Wirf doch einen Blick auf die Startseite, um eine <Styled.a href="https://fussballwortschatz.de">Übersicht aller Sprachen</Styled.a> zu sehen, zu denen es Übersetzungen gibt.</Styled.p>

      <Heading as="h2">Deine Sprache ist nicht dabei?</Heading>

      <Styled.p>Schreib uns einfach <Styled.a href="mailto:admin@fussballwortschatz.de">eine E-Mail</Styled.a></Styled.p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
